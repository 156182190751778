import { clsx } from "clsx";
import { type ComponentProps } from "react";

type IconProps = { path: string; slot?: string } & ComponentProps<"svg">;

export const Icon = (props: IconProps) => {
  const { className, path, ...rest } = props;

  return (
    <svg className={clsx("icon", className)} viewBox="0 0 24 24" {...rest}>
      <path d={path} />
    </svg>
  );
};
