"use client";

import { useToastRegion } from "@react-aria/toast";
import { useToastQueue } from "@react-stately/toast";
import { useRef } from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";

import { Notification } from "./Notification";
import { notificationQueue } from "./notificationQueue";

import styles from "./NotificationRegion.module.css";

export const NotificationRegion = () => {
  const intl = useIntl();
  const state = useToastQueue(notificationQueue);

  const ref = useRef(null);
  const { regionProps } = useToastRegion(
    {
      "aria-label": intl.formatMessage({
        defaultMessage: "Уведомления",
        id: "TnvrKP",
      }),
    },
    state,
    ref,
  );

  if (state.visibleToasts.length === 0) {
    return null;
  }

  return createPortal(
    <div className={styles.region} ref={ref} {...regionProps}>
      {state.visibleToasts.map((toast) => (
        <Notification key={toast.key} toast={toast} />
      ))}
    </div>,
    document.body,
  );
};
