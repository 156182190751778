"use client";

import { mdiClose } from "@mdi/js";
import { useToast } from "@react-aria/toast";
import { useToastQueue } from "@react-stately/toast";
import { clsx } from "clsx";
import { useRef } from "react";
import { Button } from "react-aria-components";

import { Icon } from "../icon";

import { notificationQueue } from "./notificationQueue";

import styles from "./Notification.module.css";

type NotificationProps = {
  toast: (typeof notificationQueue)["visibleToasts"][number];
};

export const Notification = (props: NotificationProps) => {
  const { toast } = props;

  const ref = useRef(null);

  const state = useToastQueue(notificationQueue);

  const { closeButtonProps, titleProps, toastProps } = useToast(
    props,
    state,
    ref,
  );

  return (
    <div
      className={clsx(
        "snackbar",
        styles.notification,
        toast.animation === "entering" && styles.notificationEntering,
        toast.animation === "exiting" && styles.notificationExiting,
      )}
      onAnimationEnd={() => {
        // Remove the toast when the exiting animation completes.
        if (toast.animation === "exiting") {
          state.remove(toast.key);
        }
      }}
      ref={ref}
      {...toastProps}
    >
      <div
        className={clsx("snackbar__text", styles.notificationText)}
        {...titleProps}
      >
        {toast.content.text}
      </div>
      {toast.content.closable && (
        <Button
          className="snackbar__close-button icon-button"
          {...closeButtonProps}
        >
          <Icon path={mdiClose} />
        </Button>
      )}
    </div>
  );
};
