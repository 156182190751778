import(/* webpackMode: "eager" */ "/app/domains/global-styles/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProvider"] */ "/app/domains/i18n/components/I18nProvider/I18nProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationRegion"] */ "/app/domains/notification/NotificationRegion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientRouterProvider"] */ "/app/domains/root-layout/ClientRouterProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/domains/root-layout/icon-dark.svg");
;
import(/* webpackMode: "eager" */ "/app/domains/root-layout/icon-light.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"domains/root-layout/RootLayout.tsx\",\"import\":\"Roboto_Flex\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"cyrillic\",\"latin\"],\"variable\":\"--font-roboto-flex\"}],\"variableName\":\"robotoFlex\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"domains/root-layout/RootLayout.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"cyrillic\",\"latin\"],\"variable\":\"--font-roboto-mono\"}],\"variableName\":\"robotoMono\"}");
